import type { FC } from "react";
import { useMemo } from "react";
import styles from "./UpcomingMatchesFilters.module.scss";
import { UpcomingMatchesFilter } from "../upcoming-matches-filter/UpcomingMatchesFilter";
import type { ButtonComponent } from "@motain/xpa-proto-files-web/lib/types/basic";
import type { Immutable } from "@/types/immutable";

const uniqueInstanceId = Math.random().toString(36).substring(7);
const uniqueIdAll = `${uniqueInstanceId}-all`;
const uniqueIdFree = `${uniqueInstanceId}-free`;
const uniqueIdPaid = `${uniqueInstanceId}-paid`;

interface UpcomingMatchesFiltersProps {
  filterButtonAll: Immutable<ButtonComponent>;
  filterButtonFree: Immutable<ButtonComponent>;
  filterButtonPaid: Immutable<ButtonComponent>;
  filter: UpcomingMatchesFilterValues;
  setFilter: (newFilter: UpcomingMatchesFilterValues) => void;
}

export enum UpcomingMatchesFilterValues {
  ALL = "all",
  FREE = "free",
  PAID = "paid",
}

export const UpcomingMatchesFilters: FC<UpcomingMatchesFiltersProps> = ({
  filterButtonAll,
  filterButtonFree,
  filterButtonPaid,
  filter,
  setFilter,
}) => {
  const buttons = useMemo(
    () => [
      {
        ...filterButtonAll,
        uniqueId: uniqueIdAll,
        filterChecked: UpcomingMatchesFilterValues.ALL,
      },
      {
        ...filterButtonFree,
        uniqueId: uniqueIdFree,
        filterChecked: UpcomingMatchesFilterValues.FREE,
      },
      {
        ...filterButtonPaid,
        uniqueId: uniqueIdPaid,
        filterChecked: UpcomingMatchesFilterValues.PAID,
      },
    ],
    [filterButtonAll, filterButtonFree, filterButtonPaid],
  );

  return (
    <div className={styles.upcomingMatchesFilter}>
      {buttons.map(({ text, uniqueId, filterChecked }) => (
        <UpcomingMatchesFilter
          key={uniqueId}
          uniqueId={uniqueId}
          checked={filter === filterChecked}
          text={text}
          value={filterChecked}
          onChangeHandler={() => {
            setFilter(filterChecked);
          }}
        />
      ))}
    </div>
  );
};
