import type { FC } from "react";
import type { Immutable } from "@/types/immutable";
import type { UpcomingMatchCard } from "@motain/xpa-proto-files-web/lib/types/upcoming_matches";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { isNonNullable } from "@/types/isNonNullable";
import styles from "./UpcomingMatchCard.module.scss";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import { getImageSourceSets } from "@/utils/helpers/getImageSourceSets";
import { LIVE_MATCH_CARD_IMAGE_SOURCE_SETS_RECORD } from "./liveMatchCardImageSourceSetsRecord";
import { pxIcon } from "@/utils/helpers/pxIcon";
import { SimpleMatchCard } from "../../match-card/simple-match-card/SimpleMatchCard";
import { UpcomingMatchesLiveBadge } from "../upcoming-matches-live-badge/UpcomingMatchesLiveBadge";

export const UpcomingMatchCardComponent: FC<Immutable<UpcomingMatchCard>> = ({
  matchCard,
  imageObject,
  liveBadge,
  partnerLogo,
}) => {
  if (typeof matchCard === "undefined") {
    return null;
  }

  const onUpcomingMatchCardClick = () => {
    trackingService.sendXpaTracking(
      matchCard.trackingEvents,
      EventType.EVENT_CLICK,
    );
  };

  return (
    <a
      onClick={onUpcomingMatchCardClick}
      href={matchCard.link}
      className={styles.upcomingMatchCard}
    >
      <div className={styles.upcomingMatchCard__imageContainer}>
        {imageObject !== undefined && (
          <div className={styles.upcomingMatchCard__image}>
            <ImageWithSets
              image={getImageSourceSets(
                LIVE_MATCH_CARD_IMAGE_SOURCE_SETS_RECORD,
                imageObject.path,
              )}
              alt={imageObject.alt}
              isPreloaded={imageObject.isPreloaded}
            />
          </div>
        )}

        {isNonNullable(liveBadge) && liveBadge !== "" && (
          <UpcomingMatchesLiveBadge
            isPreloaded={imageObject?.isPreloaded}
            liveBadgeText={liveBadge}
          />
        )}

        {isNonNullable(partnerLogo) && (
          <div className={styles.upcomingMatchCard__partnerLogo}>
            <ImageWithSets
              image={pxIcon(24, partnerLogo.path)}
              isPreloaded={partnerLogo.isPreloaded}
            />
          </div>
        )}
      </div>

      <SimpleMatchCard {...matchCard} />
    </a>
  );
};
