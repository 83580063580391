import type { FC } from "react";
import { useMemo, useState } from "react";
import type { Immutable } from "@/types/immutable";
import type { UpcomingMatchesComponent } from "@motain/xpa-proto-files-web/lib/types/upcoming_matches";
import styles from "./UpcomingMatches.module.scss";
import { isNonNullable } from "@/types/isNonNullable";
import { HorizontalScrollingContainer } from "@/components/horizontal-scrolling-container/HorizontalScrollingContainer";
import { UpcomingMatchCardComponent } from "./upcoming-match-card/UpcomingMatchCard";
import {
  UpcomingMatchesFilters,
  UpcomingMatchesFilterValues,
} from "./upcoming-matches-fitlers/UpcomingMatchesFilters";
import classNames from "classnames";
import PatternLeft from "@/public/next-assets/zigzag-pattern-left.svg";
import PatternRight from "@/public/next-assets/zigzag-pattern-right.svg";

type UpcomingMatchesProps = Immutable<UpcomingMatchesComponent> & {
  isDark?: boolean;
};

export const UpcomingMatches: FC<UpcomingMatchesProps> = ({
  matchCards,
  label,
  filterButtonAll,
  filterButtonFree,
  filterButtonPaid,
  hasPattern,
  isDark = false,
}) => {
  const [filter, setFilter] = useState(UpcomingMatchesFilterValues.ALL);

  const matchCardsList = useMemo(() => {
    switch (filter) {
      case UpcomingMatchesFilterValues.FREE:
        return matchCards.filter((card) => !card.isPaid);
      case UpcomingMatchesFilterValues.PAID:
        return matchCards.filter((card) => card.isPaid);
      default:
        return matchCards;
    }
  }, [matchCards, filter]);

  const itemsList = useMemo(() => {
    return matchCardsList.map((card) => ({
      element: <UpcomingMatchCardComponent {...card} />,
      key: `${card.uiKey}`,
    }));
  }, [matchCardsList]);

  const showButtons =
    isNonNullable(filterButtonAll) &&
    isNonNullable(filterButtonFree) &&
    isNonNullable(filterButtonPaid);

  return (
    <div
      className={classNames(
        styles.upcomingMatches,
        hasPattern && styles.upcomingMatchesWithPatterns,
      )}
    >
      {hasPattern && (
        <>
          <div className={styles.patternContainerLeft}>
            <PatternLeft className={styles.pattern} />
          </div>
          <div className={styles.patternContainerRight}>
            <PatternRight className={styles.pattern} />
          </div>
        </>
      )}
      <div className={styles.upcomingMatchesTop}>
        {label.length > 0 && <header className="label">{label}</header>}

        {showButtons && (
          <UpcomingMatchesFilters
            filterButtonAll={filterButtonAll}
            filterButtonFree={filterButtonFree}
            filterButtonPaid={filterButtonPaid}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>

      <HorizontalScrollingContainer
        desktopChunkItemsCount={3}
        mobileChunkItemsCount={1}
        tabletPortraitChunkItemsCount={2}
        tabletLandscapeChunkItemsCount={3}
        items={itemsList}
        isDark={isDark}
      ></HorizontalScrollingContainer>
    </div>
  );
};
