import type { FC } from "react";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import { pxIcon } from "@/utils/helpers/pxIcon";
import styles from "./UpcomingMatchesLiveBadge.module.scss";

export const UpcomingMatchesLiveBadge: FC<{
  isPreloaded: boolean | undefined;
  liveBadgeText: string;
}> = ({ isPreloaded, liveBadgeText }) => {
  return (
    <>
      <div className={styles.upcomingMatchCard__playVideoIcon}>
        <ImageWithSets
          image={pxIcon(
            50,
            "https://images.onefootball.com/cw/icons/play_icon.svg",
          )}
          isPreloaded={isPreloaded}
        />
      </div>

      <p className={styles.upcomingMatchCard__liveBadge}>
        <span className={styles.upcomingMatchCard__liveBadgeText}>
          {liveBadgeText}
        </span>
      </p>
    </>
  );
};
