import type { FC } from "react";
import styles from "./UpcomingMatchesFilter.module.scss";
import classNames from "classnames";

interface UpcomingMatchesFilterProps {
  onChangeHandler: () => void;
  checked: boolean;
  value: string;
  uniqueId: string;
  text: string | undefined;
}

export const UpcomingMatchesFilter: FC<UpcomingMatchesFilterProps> = ({
  onChangeHandler,
  checked,
  value,
  uniqueId,
  text,
}) => {
  return (
    <>
      <input
        onChange={onChangeHandler}
        checked={checked}
        id={uniqueId}
        type="radio"
        value={value}
        name={"priceFilter"}
        autoComplete="off"
        className={classNames(
          "screen-reader-only",
          styles.upcomingMatchesFilterRadio,
        )}
      />
      <label htmlFor={uniqueId} className={styles.upcomingMatchesFilterLabel}>
        <span className={styles.upcomingMatchesFilterLabelText}>{text}</span>
      </label>
    </>
  );
};
